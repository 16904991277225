import React from 'react';
import MODEL_TYPE from 'common/constants/modelType';
import { useProjectCreationRestricted } from 'common/featureFlags';
import { useGetClusterQuery } from 'src/api/pilot/clusters';
import { Connection } from 'src/api/pilot/connections';
import Azure from 'src/resources/icons/Azure.svg';
import BigQuery from 'src/resources/icons/BigQuery.svg';
import Databricks from 'src/resources/icons/Databricks.svg';
import GCS from 'src/resources/icons/gcs.svg';
import MSSQL from 'src/resources/icons/MSSQL.svg';
import MySQL from 'src/resources/icons/MySQL.svg';
import Oracle from 'src/resources/icons/Oracle.svg';
import Postgres from 'src/resources/icons/Postgres.svg';
import AmazonS3 from 'src/resources/icons/S3.svg';
import Snowflake from 'src/resources/icons/Snowflake.svg';

export const CONNECTION_TYPE_DEFAULT = 's3';

// https://github.com/search?q=repo%3AGretellabs%2Fmonogretel%20v1.connection_credentials_type&type=code
/**
 * note: we have both AWS and S3 here, in both uppercase and lowercase.
 * Backend is migrating to use s3 (not aws) and lowercase type names in general.
 * We need to keep the others around for now for backwards compatibility purposes.
 */
export type ConnectionFormType =
  | 's3'
  | 'gcs'
  | 'snowflake'
  | 'azure'
  | 'mysql'
  | 'mssql'
  | 'postgres'
  | 'oracle'
  | 'bigquery'
  | 'databricks';

export type ConnectionType =
  | ConnectionFormType
  | 'aws'
  | 'gretel'
  | 'AWS'
  | 'S3'
  | 'GCS'
  | 'SNOWFLAKE'
  | 'AZURE'
  | 'GRETEL';

export type FormErrors<F extends object> = Partial<Record<keyof F, string>>;

/**
 * Subset of all connection types that are used for relational databases.
 * There are some constraints and differences with these types, so we need to keep track
 * of which they are.
 */
export const RELATIONAL_CONNECTION_TYPES: ConnectionType[] = [
  'mysql',
  'mssql',
  'postgres',
  'snowflake',
  'oracle',
  'bigquery',
  'databricks',
];

export const isRelationalConnection = (connectionType: ConnectionType) => {
  return RELATIONAL_CONNECTION_TYPES.includes(connectionType);
};

export const connectionTypeFromString = (str: string): string | undefined => {
  const match = Object.keys(connectionTypes).find(ct =>
    str.toLowerCase().includes(ct)
  );
  return match ? connectionTypes[match].type : undefined;
};
/**
 * Target type sets how a connection can be used.
 * Source == input (read data from)
 * Destination == output (write data to)
 * Relational connections can only be one or the other.
 * Object storage connections can be one or both.
 */
export type ConnectionTargetType = 'source' | 'destination';
// note: API will return empty string "" for "both", this utility const/type is to help with
// within Console logic.
export const TARGET_TYPE_BOTH = '';
export type ConnectionTargetTypeBoth = typeof TARGET_TYPE_BOTH;

export type ChipColors =
  | 'default'
  | 'info'
  | 'success'
  | 'error'
  | 'secondary'
  | 'primary'
  | 'warning'
  | undefined;

export type BucketConnection = {
  bucket: string;
  glob_filter: string;
  path: string;
};

export type ContainerConnection = {
  container: string;
  glob_filter: string;
  path: string;
};

export type ConnectionMap = {
  type: ConnectionType;
  label: string;
  isComingSoon: boolean;
};

export type ConnectionSelectArgs =
  | {
      bucket?: string | undefined;
      glob_filter?: string | undefined;
      path?: string | undefined;
      connectionId: string;
      connectionType: ConnectionType;
    }
  | (Partial<Connection> & {
      container?: string;
    });
/**
 * All possible connection types, including some overlapping for
 * s3 / aws. Note that all keys are lowercased
 */
export const connectionTypes: Record<string, ConnectionMap> = {
  aws: {
    type: 's3',
    label: 'Amazon S3',
    isComingSoon: false,
  },
  s3: {
    type: 's3',
    label: 'Amazon S3',
    isComingSoon: false,
  },
  gcs: {
    type: 'gcs',
    label: 'Google Cloud Storage',
    isComingSoon: false,
  },
  mysql: {
    type: 'mysql',
    label: 'MySQL',
    isComingSoon: false,
  },
  postgres: {
    type: 'postgres',
    label: 'PostgreSQL',
    isComingSoon: false,
  },
  mssql: {
    type: 'mssql',
    label: 'MS SQL Server',
    isComingSoon: false,
  },
  snowflake: {
    type: 'snowflake',
    label: 'Snowflake',
    isComingSoon: false,
  },
  azure: {
    type: 'azure',
    label: 'Azure Blob',
    isComingSoon: false,
  },
  oracle: {
    type: 'oracle',
    label: 'Oracle Database',
    isComingSoon: false,
  },
  bigquery: {
    type: 'bigquery',
    label: 'BigQuery',
    isComingSoon: false,
  },
  databricks: {
    type: 'databricks',
    label: 'Databricks',
    isComingSoon: false,
  },
};

export const getConnectionIcon = (connectionType?: ConnectionType) => {
  switch (connectionType) {
    case 'aws':
    case 's3':
    case 'AWS':
    case 'S3':
      return <AmazonS3 height={24} width={24} />;
    case 'gcs':
    case 'GCS':
      return <GCS height={24} width={24} />;
    case 'snowflake':
    case 'SNOWFLAKE':
      return <Snowflake height={24} width={24} />;
    case 'azure':
    case 'AZURE':
      return <Azure height={24} width={24} />;
    case 'mysql':
      return <MySQL height={24} width={24} />;
    case 'mssql':
      return <MSSQL height={24} width={24} />;
    case 'postgres':
      return <Postgres height={24} width={24} />;
    case 'oracle':
      return <Oracle height={24} width={24} />;
    case 'bigquery':
      return <BigQuery height={24} width={24} />;
    case 'databricks':
      return <Databricks height={24} width={24} />;
    default:
      return <AmazonS3 height={24} width={24} />;
  }
};

// subset types that users can create via wizard flow
export const useCreateableConnectionTypes = (): ConnectionMap[] => {
  /**
   * The order of these connection types is important, as it will determine the order
   * in which they are displayed in the UI. We want to display them in alphabetical order.
   * Ensure these are ordered alphabetically based on the `label` property.
   */
  return [
    connectionTypes['s3'],
    connectionTypes['azure'],
    connectionTypes['bigquery'],
    connectionTypes['databricks'],
    connectionTypes['gcs'],
    connectionTypes['mssql'],
    connectionTypes['mysql'],
    connectionTypes['oracle'],
    connectionTypes['postgres'],
    connectionTypes['snowflake'],
  ];
};

export const connectionStatusMap = {
  VALIDATION_STATUS_UNKNOWN: {
    label: 'UNKNOWN',
    color: 'default',
  },
  VALIDATION_STATUS_VALIDATING: {
    label: 'VALIDATING',
    color: 'info',
  },
  VALIDATION_STATUS_VALID: {
    label: 'VALID',
    color: 'success',
  },
  VALIDATION_STATUS_INVALID: {
    label: 'INVALID',
    color: 'error',
  },
};

/** PLEASE MEMOIZE, a potentially lengthy iteration over all connections */
export const calculateNextDefaultName = (
  connectionType: Connection['type'] = CONNECTION_TYPE_DEFAULT,
  connections: Connection[] | undefined
): string => {
  const defaultName = `${connectionType}-my-connection`;

  if (!connections || connections.length < 1) {
    return `${defaultName}-1`;
  }
  // hold a collection of trailing numbers, stored in their respective indexes
  // it's pre-populated because I doubt we'd want to start off with `my-connection-0`
  const ordinals = ['0'];
  for (const conn of connections) {
    // we only care about connections that start with the currently focused defaultName
    if (typeof conn.name === 'string' && conn.name.startsWith(defaultName)) {
      const ordinalOfCurrent = conn.name.replace(defaultName + '-', '');
      const index = Number(ordinalOfCurrent);
      if (Number.isInteger(index) && index > 0) {
        ordinals[index] = String(index);
      }
    }
  }
  // now that we have all trailing numbers sorted, find the first one missing.
  const undefIndex = ordinals.findIndex(n => n === undefined);
  const nextOrdinal = undefIndex > 0 ? undefIndex : ordinals.length;
  return `${defaultName}-${nextOrdinal}`;
};

/**
 * Generates connection parameters for accessing extended sample data based on connection type and model type.
 * @param {ConnectionType} connectionType - The type of connection (e.g., 's3').
 * @param {MODEL_TYPE} modelType - The type of model (e.g., 'ACTGAN', 'AMPLIFY', etc.).
 * @returns {Object} - An object containing connection parameters like bucket, glob_filter, and path.
 */
export const getExtendedSampleConnectionParams = (
  connectionType: ConnectionType,
  modelType: MODEL_TYPE
) => {
  // Shared S3 parameters for all models
  const sharedS3Params = { bucket: 'gretel-sample', glob_filter: '*.csv' };
  // Define a matrix to map connection types and model types to their respective parameters
  type Matrix = Partial<
    Record<
      ConnectionType,
      Partial<
        Record<
          MODEL_TYPE,
          { bucket?: string; glob_filter?: string; path?: string }
        >
      >
    >
  >;
  const matrix: Matrix = {
    s3: {
      [MODEL_TYPE.ACTGAN]: {
        path: 'actgan',
        ...sharedS3Params,
      },
      [MODEL_TYPE.AMPLIFY]: {
        path: 'amplify',
        ...sharedS3Params,
      },
      [MODEL_TYPE.CLASSIFY]: {
        path: 'classify',
        ...sharedS3Params,
      },
      [MODEL_TYPE.EVALUATE]: {
        path: 'evaluate',
        ...sharedS3Params,
      },
      [MODEL_TYPE.GPT_X]: {
        path: 'gpt_x',
        ...sharedS3Params,
      },
      [MODEL_TYPE.TABULAR_DP]: {
        path: 'tabular_dp',
        ...sharedS3Params,
      },
      [MODEL_TYPE.DGAN]: {
        path: 'timeseries_dgan',
        ...sharedS3Params,
      },
      [MODEL_TYPE.TRANSFORM]: {
        path: 'transform',
        ...sharedS3Params,
      },
      [MODEL_TYPE.TRANSFORM_V2]: {
        path: 'transform_v2',
        ...sharedS3Params,
      },
      [MODEL_TYPE.SYNTHETICS]: {
        path: 'lstm',
        ...sharedS3Params,
      },
    },
  };
  // Return connection parameters based on provided connection type and model type
  return matrix?.[connectionType]?.[modelType] ?? {};
};
export const targetTypeBySource = {
  input: 'source',
  output: 'destination',
};

// ************* RSA encryption *************
// RSA encryption is an asymmetric encryption algorithm that uses a public key to encrypt data and a private key to decrypt it.
// SubtleCrypto is a global object that provides a way to perform encryption and decryption operations.
export const subtleCrypto = window.crypto.subtle;

// convert a binary string to an ArrayBuffer
const binStr2ab = (str: string) => {
  const buffer = new ArrayBuffer(str.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    // since we have a binary string, we can use String.charCodeAt to get the byte values
    bufferView[i] = str.charCodeAt(i);
  }
  return buffer;
};

// Import Key logic is based on example from MDN Web Docs
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey
const importRsaKey = (pem: string, algorithmName: string) => {
  // trim the pem string to remove trailing newline characters
  const trimmedPem = pem.trim();

  //validate PEM-encoded public key
  if (
    !trimmedPem.startsWith('-----BEGIN PUBLIC KEY-----') ||
    !trimmedPem.endsWith('-----END PUBLIC KEY-----')
  ) {
    throw new Error('Key provided is not a valid PEM-encoded public key.');
  }

  // fetch the part of the PEM string between header and footer
  const pemHeader = '-----BEGIN PUBLIC KEY-----';
  const pemFooter = '-----END PUBLIC KEY-----';
  const pemContents = trimmedPem.substring(
    pemHeader.length,
    trimmedPem.length - pemFooter.length - 1
  );
  // base64 decode the string to get the binary data
  // DER is a binary format for data structures described by ASN.1
  const binaryDerString = atob(pemContents);
  // convert from a binary string to an ArrayBuffer
  // We can't just use TextEncoder here because the data is binary, not text
  // TextEncoder only works with text data
  // So we have to use a custom function to convert the binary string to an ArrayBuffer
  const binaryDer = binStr2ab(binaryDerString);

  // spki = SubjectPublicKeyInfo - the data format of the public key we're importing
  return subtleCrypto.importKey(
    'spki',
    binaryDer,
    {
      name: algorithmName,
      hash: 'SHA-256',
    },
    false,
    ['encrypt', 'wrapKey']
  );
};

export const encrypt = async (rsaKeyPEM, keyID, credentials) => {
  const SYMMETRIC_ALGO_NAME = 'AES-GCM';
  const RSA_ALGO_NAME = 'RSA-OAEP';
  // generate a random Initialization Vector
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // You might be wondering - why are we generating a symmetric key?
  // Why not use the RSA public key to encrypt the credentials directly?
  // The reason is that RSA is not suitable for encrypting large amounts of data.
  // Instead, we use a symmetric key to encrypt the data, and then encrypt the symmetric key with the RSA public key.
  const symmetricKey = await subtleCrypto.generateKey(
    {
      name: SYMMETRIC_ALGO_NAME,
      length: 256,
    },
    true,
    ['encrypt']
  );

  // input must be encoded to a buffer or TypedArray
  const encoder = new TextEncoder();
  const encodedCredentials = encoder.encode(credentials);

  const encryptedCreds = await subtleCrypto.encrypt(
    {
      name: SYMMETRIC_ALGO_NAME,
      iv,
      tagLength: 128,
    },
    symmetricKey,
    encodedCredentials
  );

  const encryptedPayload = await new Blob([iv, encryptedCreds]).arrayBuffer();

  // import the RSA public key to encrypt the symmetric key
  const rsaKey = await importRsaKey(rsaKeyPEM, RSA_ALGO_NAME);

  const encryptedSymmetricKey = await subtleCrypto.wrapKey(
    'raw',
    symmetricKey,
    rsaKey,
    RSA_ALGO_NAME
  );

  return {
    asymmetric: {
      key_id: keyID,
      algorithm: 'RSA_4096_OAEP_SHA256',
      encrypted_client_key: btoa(
        String.fromCharCode(...new Uint8Array(encryptedSymmetricKey))
      ),
      data: btoa(String.fromCharCode(...new Uint8Array(encryptedPayload))),
    },
  };
};

export const useDisableConnectionCreation = (projectType, clusterGuid) => {
  const { value: projectCreationRestricted } = useProjectCreationRestricted();
  {
    /* Users can create a project if they are in non-hybrid projects 
      or in hybrid projects when their hybrid environment has key info available 
      We're using the EY projectCreationRestricted to limit who can create connections too 
      */
  }
  const { data } = useGetClusterQuery(
    { clusterId: clusterGuid || '' },
    { skip: !clusterGuid }
  );
  const keyInfo = data?.cluster?.config?.asymmetric_key;

  return projectCreationRestricted || (projectType === 'hybrid' && !keyInfo);
};
