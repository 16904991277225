import { useTheme } from '@mui/material';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import yaml from 'yaml';
import MODEL_TYPE, { MODELS_BY_TYPE } from 'common/constants/modelType';
import { sharedMutationKeys } from 'src/api';
import { ValidateWorkflowActionResponseStatus } from 'src/api/pilot/enums';
import {
  ConnectionType,
  connectionTypes,
  isRelationalConnection,
} from '../Connections/utils';
import { WorkflowState } from './duck';
import {
  InputAction,
  isModelAction,
  isModelConfig,
  isModelTemplate,
  ModelAction,
  ModelTrainRunAction,
  OutputAction,
  RelationalInputAction,
  TabularModelTrainRunAction,
  WorkflowAction,
  WorkflowConfig,
  WorkflowModelConfigType,
} from './types';

const getConnectionPrefix = (
  connectionState: WorkflowState['input'] | WorkflowState['output']
): string => connectionState.connectionType?.toLowerCase() || 'unknown';

/**
 * This is the default name we use during the Use Cases / Blueprints flow.
 * We should limit our usage of this constant -- most code should assume that the
 * model action name could be something different.
 */
export const DEFAULT_MODEL_ACTION_NAME = 'model-train-run';

const createRelationalInputActionConfig = (
  input: WorkflowState['input'],
  inputPrefix: ConnectionType
): RelationalInputAction => {
  const baseInputConfig = {
    name: `${inputPrefix}-read`,
    type: `${inputPrefix}_source`,
    connection: input.connectionId,
    config: {},
  };

  let inputConfig = baseInputConfig;

  // overwrite config based on input data source
  if (input.selectedRelationalType === 'sqlQuery') {
    inputConfig = {
      ...inputConfig,
      config: {
        queries: [{ name: input.sqlQueryName, query: input.sqlQuery }],
      },
    };
  } else if (input.selectedRelationalType === 'tableNames') {
    const formattedTables = input.tableNames?.map(item => {
      return { name: item };
    });
    inputConfig = {
      ...inputConfig,
      config: {
        sync: { mode: 'full' },
        tables: formattedTables,
      },
    };
  } else {
    inputConfig = {
      ...inputConfig,
      config: {
        sync: { mode: 'full' },
      },
    };
  }

  // add bq_dataset to config if connection type is bigquery
  if (inputPrefix === connectionTypes.bigquery.type) {
    inputConfig = {
      ...inputConfig,
      config: {
        ...inputConfig.config,
        bq_dataset: input.dataset,
      },
    };
  }

  return inputConfig;
};

export const createInputActionConfig = (
  input: WorkflowState['input']
): InputAction | RelationalInputAction => {
  const inputPrefix = getConnectionPrefix(input);

  // we only want to include bucket, path etc. if we're not using a relational connection
  if (isRelationalConnection(inputPrefix as ConnectionType)) {
    return createRelationalInputActionConfig(
      input,
      inputPrefix as ConnectionType
    );
  }
  // If connection type is azure then replace bucket with container
  if (inputPrefix === connectionTypes.azure.type) {
    return {
      name: `${inputPrefix}-read`,
      type: `${inputPrefix}_source`,
      connection: input.connectionId,
      config: {
        container: input.container,
        glob_filter: input.glob_filter,
        path: input.path,
        recursive: input.recursive,
      },
    };
  }

  return {
    name: `${inputPrefix}-read`,
    type: `${inputPrefix}_source`,
    connection: input.connectionId,
    config: {
      bucket: input.bucket,
      glob_filter: input.glob_filter,
      path: input.path,
      recursive: input.recursive,
    },
  };
};

export const createOutputActionConfig = (
  output: WorkflowState['output'],
  /**
   * `input` represents the string value for the input field for the output.
   * That is, "what action (by name) is this output consuming?"
   * For example, it's _probably_ "model-train-run", the default name of
   * our model action.
   */
  input: string
): OutputAction => {
  const outputPrefix = getConnectionPrefix(output);
  const baseOutputConfig = {
    name: `${outputPrefix}-write`,
    type: `${outputPrefix}_destination`,
    connection: output.connectionId,
    input,
    config: {},
  };

  let outputConfig = baseOutputConfig;
  const shouldDefaultToTruncate =
    outputPrefix === connectionTypes.postgres.type ||
    outputPrefix === connectionTypes.mssql.type;

  if (isRelationalConnection(outputPrefix as ConnectionType)) {
    outputConfig = {
      ...outputConfig,
      config: {
        // sync mode for Postgres and MsSql should be 'truncate'
        // otherwise it should be 'replace'
        sync: { mode: shouldDefaultToTruncate ? 'truncate' : 'replace' },
        dataset: `{outputs.${input}.dataset}`,
      },
    };
  } else {
    outputConfig = {
      ...outputConfig,
      config: {
        ...(outputPrefix === 'azure'
          ? { container: output.container }
          : { bucket: output.bucket }),
        filename: `{outputs.${input}.dataset.files.filename}`,
        input: `{outputs.${input}.dataset.files.data}`,
        path: output.path,
      },
    };
  }

  // add bq_dataset to config if connection type is bigquery
  if (outputPrefix === connectionTypes.bigquery.type) {
    outputConfig = {
      ...outputConfig,
      config: {
        ...outputConfig.config,
        bq_dataset: output.dataset,
      },
    };
  }

  return outputConfig;
};

/**
 * Note: this is designed for a very specific subset of cases, when all of the following:
 * 1. hybrid project
 * 2. NOT a relational connector
 * 3. Workflow output exists
 * 4. Output bucket is an object storage (not relational)
 */
const createReportOutputActionConfig = (
  output: WorkflowState['output'],
  /**
   * `input` represents the string value for the input field for the output.
   * That is, "what action (by name) is this output consuming?"
   * For example, it's _probably_ "model-train-run", the default name of
   * our model action.
   */
  input: string
): OutputAction => {
  const outputPrefix = getConnectionPrefix(output);
  const { connectionId, path, container, bucket } = output;

  return buildReportOutputConfig({
    connectionId,
    connectionType: outputPrefix,
    input,
    path,
    container,
    bucket,
  });
};

export const buildReportOutputConfig = ({
  connectionId,
  connectionType,
  input,
  path,
  container,
  bucket,
}: {
  connectionId: string | undefined;
  connectionType: string;
  input: string;
  path?: string;
  container?: string;
  bucket?: string;
}): OutputAction => {
  return {
    name: `${connectionType}-write-reports`,
    type: `${connectionType}_destination`,
    connection: connectionId,
    input,
    config: {
      filename: `{outputs.${input}.reports.all.filename}`,
      input: `{outputs.${input}.reports.all.data}`,
      path,
      ...(connectionType === 'azure' ? { container } : { bucket }),
    },
  };
};

/**
 *
 * the model "type" on the configs is a bit nested,
 * so we need to try to parse it out
 */
const getModelTypeFromModelConfig = (model?: object): string | undefined => {
  if (
    !model ||
    typeof model !== 'object' ||
    !Object.keys(model).includes('models')
  ) {
    return undefined;
  }

  if (Array.isArray(model['models']) && model['models'].length > 0) {
    const actualModelConfig = model['models'][0];
    if (actualModelConfig && typeof actualModelConfig === 'object') {
      return Object.keys(actualModelConfig)[0];
    }
  }
};

const getRunParamsForModelType = (
  inputActionName: string,
  modelType?: MODEL_TYPE | 'transforms'
) => {
  switch (modelType) {
    case 'actgan':
    case 'gpt_x':
    case 'tabular_dp':
    case 'timeseries_dgan':
    case 'amplify':
    case 'synthetics':
    case 'navigator_ft':
      return { params: { num_records: 5000 } };

    case 'transforms':
    case 'classify':
      return { params: {} };

    case 'navigator':
      return {
        data_source: `{outputs.${inputActionName}.dataset.files.data}`,
      };

    default:
  }
};

export const createModelAction = (
  /** inputActionName - name of the action that is consumed by this one (e.g., the source connection) */
  inputActionName: string,
  project_id: string,
  model: Record<string, unknown>,
  sourceConnectionType: ConnectionType,
  /** name for this action we are creating */
  actionName: string = DEFAULT_MODEL_ACTION_NAME
): ModelAction => {
  const modelType = getModelTypeFromModelConfig(model) as
    | MODEL_TYPE
    | undefined;

  const modelHasTabularSupport =
    modelType && MODELS_BY_TYPE[modelType].hasTabularActionSupport;

  // we have a different model action config for relational connectors, but it does not support all model types
  return isRelationalConnection(sourceConnectionType) && modelHasTabularSupport
    ? createTabularAction({
        actionName,
        inputActionName,
        model,
        modelType,
        project_id,
      })
    : createModelTrainRunAction({
        actionName,
        inputActionName,
        model,
        modelType,
        project_id,
      });
};
export const createModelTrainRunAction = ({
  actionName,
  inputActionName,
  project_id,
  model,
  modelType,
}: {
  actionName: string;
  inputActionName: string;
  project_id?: string;
  model: Record<string, unknown>;
  modelType?: MODEL_TYPE;
}): ModelTrainRunAction => {
  const isGretelModelWithRunParams =
    modelType && MODELS_BY_TYPE[modelType]?.withWorkflowModelActionRunParams;
  // we have a different model action config for relational connectors
  return {
    name: actionName,
    type: WorkflowModelConfigType.GRETEL_MODEL,
    input: inputActionName,
    config: {
      project_id,
      model_config: model,
      ...(isGretelModelWithRunParams
        ? {
            run: getRunParamsForModelType(
              inputActionName,
              modelType as MODEL_TYPE
            ),
          }
        : {}),
      training_data: `{outputs.${inputActionName}.dataset.files.data}`,
    },
  };
};

export const createTabularAction = ({
  actionName,
  inputActionName,
  project_id,
  model,
  modelType,
}: {
  actionName: string;
  inputActionName: string;
  project_id?: string;
  model: Record<string, unknown>;
  modelType?: MODEL_TYPE;
}): TabularModelTrainRunAction => {
  const isGretelTabularWithRun =
    modelType && MODELS_BY_TYPE[modelType]?.includeTabularNumRecordsMultiplier;

  return {
    name: actionName,
    type: WorkflowModelConfigType.GRETEL_TABULAR,
    input: inputActionName,
    config: {
      project_id,
      train: {
        model_config: model,
        dataset: `{outputs.${inputActionName}.dataset}`,
      },
      ...(isGretelTabularWithRun ? { run: { num_records_multiplier: 1 } } : {}),
    },
  };
};

export const getModelFromWorkflowConfig = (workflowConfig?: WorkflowConfig) => {
  if (!workflowConfig?.actions || workflowConfig.actions.length < 1) {
    return undefined;
  }

  // Attempt to find the first model action
  const action = workflowConfig.actions.find(isModelAction);

  if (!action?.config) {
    return undefined;
  }

  if ('model' in action.config && action.config.model) {
    return action.config.model;
  }

  if ('model_config' in action.config && action.config.model_config) {
    if (isModelTemplate(action.config.model_config)) {
      return action.config.model_config.from;
    }
    if (isModelConfig(action.config.model_config)) {
      return action.config.model_config;
    }
  }

  // In the tabular model action, model/model_config is nested under 'train'
  if ('train' in action.config && action.config.train) {
    if ('model' in action.config.train) {
      return action.config.train.model;
    }

    const { model_config } = action.config.train;
    if (action.config.train.model_config) {
      if (isModelTemplate(model_config)) {
        return model_config.from;
      }
      if (isModelConfig(model_config)) {
        return model_config;
      }
    }
  }
};

export const generateWorkflowConfig = (
  workflow: WorkflowState,
  trainModelYaml: string
) => {
  const { input, projectId, output, trigger, projectType } = workflow;
  const inputConnectionType = getConnectionPrefix(input) as ConnectionType;
  const trainModelJSON = yaml.parse(trainModelYaml || '');

  const withSchedule = trigger.when === 'Schedule' && !!trigger.cron;

  /**
   * actions: [input, model, optional data output, optional report output]
   */
  const inputAction = createInputActionConfig(input);
  const modelTrainRunAction = createModelAction(
    inputAction.name,
    projectId || '',
    trainModelJSON,
    inputConnectionType
  );
  const actions: WorkflowAction[] = [inputAction, modelTrainRunAction];

  if (output.source === 'connection') {
    const outputAction = createOutputActionConfig(
      output,
      modelTrainRunAction.name
    );
    actions.push(outputAction);
  }

  const shouldAddReportOutput =
    projectType === 'hybrid' &&
    !isRelationalConnection(inputConnectionType) &&
    output.connectionType &&
    !isRelationalConnection(
      output.connectionType.toLowerCase() as ConnectionType
    );

  if (shouldAddReportOutput) {
    actions.push(
      createReportOutputActionConfig(output, modelTrainRunAction.name)
    );
  }

  return {
    name: `my-${inputConnectionType}-workflow`,
    ...(withSchedule ? { trigger: { cron: { pattern: trigger.cron } } } : null),
    actions,
  };
};

export const validateWorkflowCacheKeys = {
  input: sharedMutationKeys.validateInputWorkflowAction,
  output: sharedMutationKeys.validateOutputWorkflowAction,
};

export const useValidationStatusColorMap = () => {
  const theme = useTheme();
  return {
    [ValidateWorkflowActionResponseStatus.VALIDATION_STATUS_UNKNOWN]:
      theme.palette.warning.main,
    [ValidateWorkflowActionResponseStatus.VALIDATION_STATUS_VALIDATING]:
      theme.palette.warning.main,
    [ValidateWorkflowActionResponseStatus.VALIDATION_STATUS_VALID]:
      theme.palette.success.main,
    [ValidateWorkflowActionResponseStatus.VALIDATION_STATUS_INVALID]:
      theme.palette.error.main,
  };
};

export const updateConfigWithAction = (
  config: WorkflowConfig,
  actionName: string,
  updatedAction: WorkflowAction
): WorkflowConfig => {
  const actionIndex = config.actions?.findIndex(
    action => action.name === actionName
  );
  if (actionIndex === -1) {
    throw new Error("Can't find action in workflow config");
  }

  return produce(config, draft => {
    draft.actions[actionIndex] = updatedAction;

    // if the action name changed, update any references to it.
    if (updatedAction.name !== actionName) {
      draft.actions.forEach((action, i) => {
        draft.actions[i] = updateActionNameReferences(
          action,
          actionName,
          updatedAction.name
        );
      });
    }
  });
};

export const updateActionNameReferences = (
  action: WorkflowAction,
  prevInputName: string,
  newInputName: string
): WorkflowAction => {
  const newAction = cloneDeep(action);

  if ('input' in newAction && newAction.input === prevInputName) {
    newAction.input = newInputName;
  }

  if ('config' in newAction) {
    // update template strings that use the newAction name
    Object.entries(newAction.config).forEach(([k, v]) => {
      if (typeof v === 'string' && v.startsWith('{')) {
        newAction.config[k] = v.replace(prevInputName, newInputName);
      }
      // handle nested configuration
      if (typeof v === 'object' && v !== null) {
        Object.entries(newAction.config[k]).forEach(([k2, v2]) => {
          if (typeof v2 === 'string' && v2.startsWith('{')) {
            newAction.config[k][k2] = v2.replace(prevInputName, newInputName);
          }
        });
      }
    });
  }
  return newAction;
};
