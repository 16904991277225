import React, { useState } from 'react';
import { Button } from '@mui/material';
import useConsoleRoute from 'common/utils/useConsoleRoute';
import MODEL_STATUS from 'constants/modelStatus';
import { useUpdateModelMutation } from 'src/api';
import { Model } from 'src/api/types/Model';
import { CancelModelDialog } from 'src/Console/Projects/Models/Create/CancelModelDialog';
import { SELECTED_PROJECT_MODELS } from 'src/routes';

type Props = { model: Model };

const CancelTraining = ({ model }: Props) => {
  const { go } = useConsoleRoute({ withRouterContext: true });
  const [showModal, setShowModal] = useState(false);
  const [updateModel] = useUpdateModelMutation();

  const handleCancelModel = React.useCallback(() => {
    setShowModal(false);
    updateModel({
      status: MODEL_STATUS.CANCELLED,
      guid: model.project_guid,
      uid: model.uid,
    });
    go(SELECTED_PROJECT_MODELS);
  }, [model.uid, model.project_guid, go, updateModel, setShowModal]);

  return (
    <>
      <Button
        color="error"
        data-testid="model-destructive-action"
        onClick={() => setShowModal(true)}
      >
        Cancel training
      </Button>

      <CancelModelDialog
        open={showModal}
        onCancelModel={handleCancelModel}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

CancelTraining.displayName = 'CancelTraining';

export default CancelTraining;
