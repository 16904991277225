import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export type CancelDialogProps = React.PropsWithChildren<{
  isOpen: boolean;
  onNevermind: () => void;
  onConfirm: () => void;
  title?: string;
  subtitle?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
}>;

/**
 * CancelDialog provides a reusable dialog component
 * for confirming that a user wishes to abandon
 * a process or cancel their in progress changes.
 *
 * This component expects your component that uses it
 * to control the state of whether it should open or not.
 *
 * You can pass in an optional children prop to render content specific to
 * your scenario.
 */
export const CancelDialog = ({
  isOpen,
  onNevermind,
  onConfirm,
  title = 'Are you sure you want to cancel?',
  subtitle = 'By clicking confirm, all progress will be lost.',
  confirmBtnText = 'Confirm',
  cancelBtnText = 'Nevermind',
  children,
}: CancelDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onNevermind}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle component="h3">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">{subtitle}</DialogContentText>
        {children && (
          <Box
            sx={theme => ({
              border: 1,
              borderColor: 'border.default',
              borderRadius: theme.shape.radii.base,
              marginTop: 1.5,
              padding: 4,
            })}
          >
            {children}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onConfirm}>
          {confirmBtnText}
        </Button>
        <Button onClick={onNevermind} variant="text" color="secondary">
          {cancelBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
